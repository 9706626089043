export default [
  {
    component: 'CNavTitle',
    name: 'Услуги',
  },
  {
    component: 'CNavGroup',
    name: 'Реклама',
    to: '/base',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'В Прессе',
        to: '/base/cards',
      },
      {
        component: 'CNavItem',
        name: 'На ТВ',
        to: '/base/cards',
      },
      {
        component: 'CNavItem',
        name: 'На радио',
        to: '/base/cards',
      },
      {
        component: 'CNavItem',
        name: 'Наружная реклама',
        to: '/base/cards',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'PR',
    to: '/buttons',
    icon: 'cil-cursor',
    items: [
      {
        component: 'CNavItem',
        name: 'PR',
        to: '/base/cards',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Промо',
    to: '/forms',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Промо',
        to: '/promo',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Креатив',
    to: '/icons',
    icon: 'cil-brush',
    items: [
      {
        component: 'CNavItem',
        name: 'Creative',
        to: '/base/cards',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Интернет',
    to: '/icons',
    icon: 'cil-storage',
    items: [
      {
        component: 'CNavItem',
        name: 'Интернет',
        to: '/charts',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Мероприятия',
    to: '/icons',
    icon: 'cil-calendar',
    items: [
      {
        component: 'CNavItem',
        name: 'Мероприятия',
        to: '/base/cards',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Сайты',
    to: '/notifications',
    icon: 'cil-sitemap',
    items: [
      {
        component: 'CNavItem',
        name: 'Cards',
        to: '/base/cards',
      },
      {
        component: 'CNavItem',
        name: 'Cards',
        to: '/base/cards',
      },
      {
        component: 'CNavItem',
        name: 'Cards',
        to: '/base/cards',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Исследования',
    to: '/base/cards',
    icon: 'cil-chart-pie',
    badge: {
      color: 'primary',
      text: 'NEW',
      shape: 'pill',
    },
  },
  {
    component: 'CNavTitle',
    name: 'Достижения',
  },
  {
    component: 'CNavItem',
    name: 'MAX-показатели',
    to: '/dashboard#max',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
