<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" alt="csadv logo" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Контакты
      </CDropdownHeader>
      <CDropdownItem href="tel:+74951471980">
        <CIcon icon="cil-phone" /> +7 495 147 19 80
        <!-- CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge -->
      </CDropdownItem>
      <CDropdownItem href="mailto:csadv@csadv.ru">
        <CIcon icon="cil-envelope-open" /> Заказ
        <CBadge color="success" class="ms-auto">eMail</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cilScreenSmartphone" /> Telegramm
        <CBadge color="danger" class="ms-auto">_bot</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cibVk" /> Comments
        <CBadge color="warning" class="ms-auto">VK.com</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> WhatsApp
        <CBadge color="success" class="ms-auto">ЧаВо</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Документы
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> С чего начать </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Реквизиты </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">onLine</CBadge>
      </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-shield-alt" /> ЭДО </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem> <CIcon icon="cil-lock-locked" /> Войти </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem> <CIcon icon="cil-lock-locked" /> Брифы </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary" class="ms-auto">CSADV</CBadge>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/brand/cs_logo40x40.webp'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
}
</script>
