<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CAvatar :icon="logo" height="48" alt="Logo csadv" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/#/base/cards">Услуги</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="/#/dashboard">Клиенты</CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CNavLink href="tel:+74951471980" target="_blank">
            <CIcon class="mx-2" icon="cil-phone" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            href="https://wa.me/74951471980?text=Я%20заинтересован%20в%20покупке%20вашей"
            target="_blank"
          >
            <CIcon class="mx-2" icon="cilScreenSmartphone" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" target="_blank">
            <CIcon class="mx-2" icon="cilSend" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="mailto:csadv@csadv.ru" target="_blank">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import logo from '@/assets/brand/cs_logo40x40.webp'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
